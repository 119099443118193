/*!
*   WS Starter
*   Designed by LRS Web Solutions (http://lrswebsolutions.com)
*   Visit http://lrswebsolutions.com
*/

html,
body {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
  background: white;
}

* {
  box-sizing: border-box;
}

body {
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  &.default {
    background-image: url('../images/ask/sm-landscape.jpg');
    @media only screen and (min-width: 0) and (max-width: 667px) and (orientation : landscape) {
      background-image: url('../images/ask/sm-landscape.jpg');
    }
    @media only screen and (min-width: 0) and (max-width: 667px) and (orientation : portrait) {
      background-image: url('../images/ask/sm-portrait.jpg');
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation : landscape) {
      background-image: url('../images/ask/md-landscape.jpg');
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation : portrait) {
      background-image: url('../images/ask/md-portrait.jpg');
    }
    @media only screen and (min-width : 1025px) and (orientation : landscape) {
      background-image: url('../images/ask/lg-landscape.jpg');
    }
    @media only screen and (min-width : 1025px) and (orientation : portrait) {
      background-image: url('../images/ask/lg-portrait.jpg');
    }
  }
  &.yes {
    background-image: url('../images/yes/sm-landscape.jpg');
    background-position: top center;
    @media only screen and (min-width: 0) and (max-width: 667px) and (orientation : landscape) {
      background-image: url('../images/yes/sm-landscape.jpg');
    }
    @media only screen and (min-width: 0) and (max-width: 667px) and (orientation : portrait) {
      background-image: url('../images/yes/sm-portrait.jpg');
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation : landscape) {
      background-image: url('../images/yes/md-landscape.jpg');
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation : portrait) {
      background-image: url('../images/yes/md-portrait.jpg');
    }
    @media only screen and (min-width : 1025px) and (orientation : landscape) {
      background-image: url('../images/yes/lg-landscape.jpg');
    }
    @media only screen and (min-width : 1025px) and (orientation : portrait) {
      background-image: url('../images/yes/lg-portrait.jpg');
    }
  }
  &.no {
    background-image: url('../images/no/sm-landscape.jpg');
    background-position: top center;
    @media only screen and (min-width: 0) and (max-width: 667px) and (orientation : landscape) {
      background-image: url('../images/no/sm-landscape.jpg');
    }
    @media only screen and (min-width: 0) and (max-width: 667px) and (orientation : portrait) {
      background-image: url('../images/no/sm-portrait.jpg');
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation : landscape) {
      background-image: url('../images/no/md-landscape.jpg');
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation : portrait) {
      background-image: url('../images/no/md-portrait.jpg');
    }
    @media only screen and (min-width : 1025px) and (orientation : landscape) {
      background-image: url('../images/no/lg-landscape.jpg');
    }
    @media only screen and (min-width : 1025px) and (orientation : portrait) {
      background-image: url('../images/no/lg-portrait.jpg');
    }
  }
  &.trump {
    background-image: url('../images/trump/sm-landscape.jpg');
    background-position: top center;
    @media only screen and (min-width: 0) and (max-width: 667px) and (orientation : landscape) {
      background-image: url('../images/trump/sm-landscape.jpg');
    }
    @media only screen and (min-width: 0) and (max-width: 667px) and (orientation : portrait) {
      background-image: url('../images/trump/sm-portrait.jpg');
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation : landscape) {
      background-image: url('../images/trump/md-landscape.jpg');
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation : portrait) {
      background-image: url('../images/trump/md-portrait.jpg');
    }
    @media only screen and (min-width : 1025px) and (orientation : landscape) {
      background-image: url('../images/trump/lg-landscape.jpg');
    }
    @media only screen and (min-width : 1025px) and (orientation : portrait) {
      background-image: url('../images/trump/lg-portrait.jpg');
    }
  }
}

.question {
  display: none;

  text-align: center;
  p {
    @include font-size(20, 23);
    padding: 10px 20px;
    margin: 0;
    background-color: rgba(#f2f2f2, 0.65);
  }
  .answer {
    display: block;
    padding: 10px 20px;
    margin: 0 0 15px 0;
    background-color: rgba(#f2f2f2, 0.65);
    border-bottom: 1px solid #9e9e9e;

    @include font-size(26, 29);
  }
}

.ask {
  position: absolute;
  top: 100px;

  width: 100%;
  padding: 20px;
  @media only screen and (min-width: 0) and (max-width: 667px) and (orientation : portrait) {
    top: 100px;
    left: auto;
    max-width: 380px;
  }
  @media only screen and (min-width: 0) and (max-width: 667px) and (orientation : landscape) {
    top: 75px;
    right: 0;
    left: 0;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation : portrait) {
    top: 300px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation : landscape) {
    top: 125px;
    max-width: 500px;
    left: auto;
    right: 0;
  }
  @media only screen and (min-width : 1025px) {
    top: 125px;
    max-width: 500px;
    left: auto;
    right: 0;
  }
  input[type='text'] {
    -webkit-appearance: none;
    width: 100%;
    height: 3rem;
    padding: 10px 20px;
    margin: 0 0 15px 0;
    text-align: center;
    font-size: 1rem;
    background-color: rgba(#f2f2f2, 0.65);
    border: none;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    transition: all 0.3s;

    @include font-size(20, 20);
    &:focus {
      background-color: rgba(#f2f2f2, 0.8);
      border-color: 1px solid #666;
      outline: 0;
    }
    &::-webkit-input-placeholder {
      color: black;

      @include font-size(20, 20);
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: black;

      @include font-size(20, 20);
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: black;

      @include font-size(20, 20);
    }
    &:-ms-input-placeholder {
      color: black;

      @include font-size(20, 20);
    }
  }
  .buttons {
    width: 100%;
    text-align: center;
  }
  input[type='submit'] {
    -webkit-appearance: none;
    height: 36px;
    padding: 0 2rem;
    line-height: 36px;
    letter-spacing: 0.5px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #26a69a;
    border: none;
    border-radius: 2px;
    outline: 0;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    transition: all 0.3s ease-out;
    &:hover {
      background-color: #2bbbad;
      box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15);
    }
  }
}

.question {
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  bottom: 40px;
  top: auto;
  @media only screen and (min-width: 768px) and (orientation : landscape) {
    top: 100px;
    right: 0;
    max-width: 500px;
    left: auto;
    bottom: auto;
  }
  @media only screen and (min-width: 1025px) and (orientation : landscape) {
    max-width: 650px;
    bottom: auto;
  }
  input[type='submit'] {
    -webkit-appearance: none;
    height: 36px;
    padding: 0 2rem;
    line-height: 36px;
    letter-spacing: 0.5px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    background-color: #26a69a;
    border: none;
    border-radius: 2px;
    outline: 0;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
    transition: all 0.3s ease-out;
    &:hover {
      background-color: #2bbbad;
      box-shadow: 0 5px 11px 0 rgba(0,0,0,0.18),0 4px 15px 0 rgba(0,0,0,0.15);
    }
  }
}

.hidden {
  display: none;
}
