dl.accordion {
  dt.accordion-title {
    position: relative;
    cursor: pointer;
    &:after {
      position: absolute;
      top: 0;
      right: 0;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: normal;
      text-decoration: inherit;
      content: '\f067';
    }
    &.open {
      &:after {
        content: '\f068';
      }
    }
  }
  dd.accordion-content {
    display: none;
    margin-left: 0;
  }
}
