// landscape media queries
$small-landscape: '(max-width: 40em) and (orientation: landscape)';
$medium-landscape: '(min-width: 40.063em) and (max-width: 64em) and (orientation: landscape)';
$medium-up-landscape: '(min-width: 40.063em) and (orientation: landscape)';

// colors
$palettes: (
  blues: (
    1: #c3dfff,
    2: #8db2dc
  ),
  grays: (
    1: #e9e9ea,
    2: #b7b6b4
  ),
  tans: (
    1: #e3ded6,
    2: #9a9792
  )
);

@function palette($palette, $tone: 'base') {
  @return map-get(map-get($palettes, $palette), $tone);
}

// usage background: palette(grays, 1);


// fonts
