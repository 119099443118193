// BASE TRANSITION MIXIN
@mixin transition($property: all, $duration: 0.2s, $ease: ease) {
  transition: $property $duration $ease;
}
// USAGE
// @include transition(all, 0.2s, ease);


// RESPONSIVE MEDIA QUERIES
@mixin respond($min: 0, $max: 0, $prop: 'width', $media: 'screen') {
  $query: '';

  @if $min != 0 and $max != 0 {
    $query: '(min-#{$prop}: #{$min}) and (max-#{$prop}: #{$max})';
  } @else if $min != 0 and $max == 0 {
    $query: '(min-#{$prop}: #{$min})';
  } @else if $min == 0 and $max != 0 {
    $query: '(max-#{$prop}: #{$max})';
  }

  @media only #{$media} and #{$query} {
    @content;
  };
}

// CONVERT PX FONT AND LINE HEIGHTS TO REMS
@mixin font-size($sizeValue: 1, $line: $sizeValue * 1.5) {
  font-size: $sizeValue / 16 + rem;
  line-height: $line / 16 + rem;
}
// USAGE
// @include font-size(12, 16);
