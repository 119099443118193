.tab-navigation {
  display: none;
  margin-left: 0;

  @include respond(768px) {
    display: block;
  }
  li {
    display: inline-block;
    cursor: pointer;
  }
}

.tab {
  @include respond(768px) {
    &.open:not(:first-child) {
      display: none;
    }
  }
  .tab-content {
    display: none;
  }
  &.open .tab-content {
    display: block;
  }
}

.tab-heading {
  display: block;
  color: #fff;
  cursor: pointer;
  background: #666;

  @include respond(768px) {
    display: none;
  }
}
